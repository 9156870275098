import { useState } from "react";
import { FiMinus, FiPlus, FiShoppingCart, FiX } from "react-icons/fi";

export function PopUpProductComponent({ product, callback, handleClose }) {
  const [count, setCount] = useState(1);
  const [notes, setNotes] = useState("-");

  return (
    <div className="w-full h-full bg-slate-600 bg-opacity-60 fixed z-10 flex justify-center items-center left-0 top-0">
      <div className="w-[450px] min-h-[600px] bg-white rounded-3xl px-[50px] py-[35px] text-center flex flex-col items-center relative">
        <button
          className="absolute right-5 top-5"
          onClick={() => {
            handleClose();
          }}
        >
          <FiX size={24} />
        </button>
        <h3 className="font-bold text-[24px] w-10/12 line-clamp-3">
          {product.name}
        </h3>
        <p className="line-clamp-2 text-[20px]">{product.description}</p>

        <h4 className="text-[20px] font-bold">
          Stock : {parseInt(product.stock) > 1000 ? "Unlimited" : product.stock}
        </h4>

        <div className="mt-6 w-full overflow-hidden flex justify-center items-center">
          <img src={product.displayPic} className="w-full" />
        </div>

        <div className="mt-8">
          <h3 className="font-bold text-[40px] text-main">
            Rp {product.price}
          </h3>
        </div>

        <div className="flex mt-8 gap-5">
          <button
            onClick={() => {
              if (count > 1) setCount(count - 1);
            }}
            className="bg-slate-200 text-slate-900 hover:text-white hover:bg-main rounded-xl w-[60px] h-[60px] flex justify-center items-center"
          >
            <FiMinus size={24} />
          </button>
          <div className="text-center w-[100px]">
            <h1 className="font-extrabold text-[35px]">{count}</h1>
          </div>
          <button
            onClick={() => {
              if (parseInt(product.stock) > count) setCount(count + 1);
            }}
            className="bg-slate-200 text-slate-900 hover:text-white hover:bg-main rounded-xl w-[60px] h-[60px] flex justify-center items-center"
          >
            <FiPlus size={24} />
          </button>
        </div>

        <div className="mt-8 w-full">
          <h3 className="font-bold text-[20px] text-left">Notes</h3>
          <textarea
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 mt-3 text-xl"
            rows={3}
            placeholder="spicy, medium, or original"
          ></textarea>
        </div>

        <div className="flex justify-center mt-10">
          {parseInt(product.stock) > 0 ? (
            <button
              onClick={() => {
                callback({ count: count, notes: notes });
              }}
              className="flex gap-5 items-center rounded-lg bg-main text-white text-[20px] py-2 px-2 w-[200px]"
            >
              <div className="rounded-lg bg-yellow-400 w-[40px] h-[40px] flex justify-center items-center text-slate-900">
                <FiShoppingCart size={24} />
              </div>
              Add to cart
            </button>
          ) : (
            <h4 className="text-main f-h4">Product Sold Out</h4>
          )}
        </div>
      </div>
    </div>
  );
}
