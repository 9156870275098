import image404 from "src/assets/images/404.png";

export function ErrorComponent() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <img src={image404} />
      <h1 className="text-center font-bold text-[32px] text-gray-900">
        Under Construction
      </h1>
    </div>
  );
}
