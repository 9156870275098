import { gql } from "@apollo/client";

export const CREATE_TRANSACTION = gql`
  mutation CreateQrisSnap($input: CreateQrisSnapInput!) {
    createQrisSnap(input: $input) {
      transaction {
        id
        redirectUrl
        token
      }
    }
  }
`;
