import { useContext, useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiHome, FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import { ButtonComponentLite } from "src/ui/components/button/button-component";
import { MarqueComponent } from "src/ui/components/marque";
import { PaymentSection } from "./section/PaymentSection";
import { NavbarSecondaryComponent } from "src/ui/components/navbar/navbar-component";
import { PopUpCloseSection } from "./section/PopUpCloseSection";
import { IklanComponent } from "src/ui/components/iklan";
import { PaymentSuccessSection } from "./section/PaymentSuccessSection";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { useMutation, useQuery } from "@apollo/client";
import { MenuComponent } from "./component/MenuComponent";
import { ChooseTableNumberSection } from "./section/ChooseTableNumberSection";
import { BillSection } from "./section/BillSection";
import { UPDATE_TABLE_NUMBER } from "src/graphql/mutation/UpdateTableNumber";
import { LoadingComponent } from "src/ui/components/loading/loading-component";
import { ErrorComponent } from "src/ui/components/error/error-component";
import { EmptyComponent } from "./component/EmptyComponent";

export function OrderListPage() {
  const navigate = useNavigate();
  const { typeService } = useParams();

  const { cart, setCart } = useContext(CartContext);

  const refClose = useRef(null);
  const refTableNumber = useRef(null);
  const refBill = useRef(null);
  const refPayment = useRef(null);
  const refPaymentSuccess = useRef(null);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
    refetch: refetchTenants,
  } = useQuery(GET_TENANTS);

  const [
    editTableNumber,
    {
      loading: loadingEditTableNumber,
      error: errorEditTableNumber,
      data: dataEditTableNumber,
    },
  ] = useMutation(UPDATE_TABLE_NUMBER);

  const updateTableNumber = async (resOrder) => {
    const res = await editTableNumber({
      variables: {
        input: {
          orderCustomerId: resOrder.id,
          tableNumber: "1",
        },
      },
    });

    if (loadingEditTableNumber == false && errorEditTableNumber == undefined) {
      return true;
    }
  };

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) return <ErrorComponent />;

  return (
    <div className="h-full relative overflow-hidden flex flex-col items-center">
      <PopUpCloseSection
        myRef={refClose}
        callback={() => refClose.current?.close()}
      />

      <BillSection
        myRef={refBill}
        tableNumber={1}
        typeService={typeService}
        cart={cart}
        callback={() => {
          refBill.current?.close();
          refPayment.current?.showModal();
        }}
        handleCancel={() => {
          refBill.current?.close();
        }}
      />

      <PaymentSection
        tableNumber="1"
        myRef={refPayment}
        typeService={typeService}
        cart={cart}
        action={async (resOrder) => {
          const res = await updateTableNumber(resOrder);
          if (res) {
            refPayment.current?.close();
            refPaymentSuccess.current?.showModal();

            setTimeout(() => {
              navigate(`/${typeService}/print/${resOrder.id}/1`);
            }, 2000);
          }
        }}
        handleCancel={() => {
          refPayment.current?.close();
        }}
      />

      <PaymentSuccessSection myRef={refPaymentSuccess} />

      {/* <IklanComponent /> */}

      <div className="w-11/12 flex justify-center">
        <NavbarSecondaryComponent
          title="Order List"
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            refClose.current?.showModal();
          }}
        />
      </div>

      <div className="w-full bg-gray-200 grow flex flex-col gap-2 overflow-scroll">
        {cart.length == 0 ? <EmptyComponent /> : null}
        {dataTenants.tenants.map((tenant, index) => {
          return tenant.menus.map((menu, i) => {
            if (cart.filter((x) => x.menuId == menu.id).length > 0) {
              return <MenuComponent menu={menu} key={`menu_${menu.id}`} />;
            }
          });
        })}
        <div className="bg-slate-200 h-0.5 w-full"></div>
      </div>

      <div className="w-full bg-white flex items-center justify-between px-[75px] py-[35px]">
        <div className="">
          <p className="font-bold text-[24px]">All Items</p>
        </div>
        <div className="flex items-center gap-4">
          <p className=" text-[20px]">Total</p>
          <div className="mr-3">
            <h2 className="font-extrabold text-[35px] text-main whitespace-nowrap">
              {` Rp ${cart.reduce(
                (temp, x) => (x.buy == true ? temp + x.price * x.amount : temp),
                0,
                0
              )} `}
            </h2>
          </div>
          <ButtonComponentLite
            title="Checkout"
            handle={() => {
              if (cart.length > 0) {
                const tempCart = cart.filter((x) => x.buy == true);
                const tempCart2 = [];
                {
                  dataTenants.tenants.map((tenant, index) => {
                    return tenant.menus.map((menu, i) => {
                      if (
                        tempCart.filter((x) => x.menuId == menu.id).length > 0
                      ) {
                        tempCart2.push(
                          ...tempCart.filter((x) => x.menuId == menu.id)
                        );
                      }
                    });
                  });
                }

                setCart(tempCart2);
                refBill.current?.showModal();
              }
            }}
          />
        </div>
      </div>

      {/* <MarqueComponent /> */}
    </div>
  );
}
