import React, { useContext, useState } from "react";
import { CartContext } from "src/context/CartContext";
import { ProductComponent } from "./ProductComponent";

export function MenuComponent({ menu }) {
  const { cart, setCart } = useContext(CartContext);
  const [tenantBuy, setTenantBuy] = useState(true);

  return (
    <div className="bg-white">
      <div className="border-t-4 border-b-4 border-gray-200 px-[50px] flex items-center py-4">
        <div className="flex items-center">
          <input
            id="default-checkbox"
            type="checkbox"
            checked={tenantBuy}
            onChange={() => {
              setTenantBuy(!tenantBuy);
              cart.map((product) => {
                if (product.menuId == menu.id) {
                  product.buy = !tenantBuy;
                }
              });
              setCart([...cart]);
            }}
            className="w-6 h-6 text-main bg-gray-100 border-gray-300 rounded focus:ring-main dark:focus:ring-main dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="ml-5 text-[24px] font-semibold text-gray-900 dark:text-gray-300"
          >
            {menu.name}
          </label>
        </div>
      </div>
      <div className="flex flex-col gap-2 py-6">
        {cart.map((product, index) => {
          if (product.menuId == menu.id) {
            return (
              <ProductComponent
                key={`product_${product.idForBuy}`}
                product={product}
                removeProduct={() => {
                  const newCart = cart.filter((item) => {
                    return item.idForBuy != product.idForBuy;
                  });
                  setCart(newCart);
                }}
                handleMin={() => {
                  if (product.amount > 1) {
                    product.amount = product.amount - 1;
                    setCart([...cart]);
                  }
                }}
                handlePlus={() => {
                  product.amount = product.amount + 1;
                  setCart([...cart]);
                }}
                handleBuy={() => {
                  product.buy = !product.buy;
                  setCart([...cart]);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}
