import { useContext, useEffect, useState } from "react";
import { AppContextProvider } from "./context/AppContextProvider";
import { TokenContext } from "./context/TokenContext";
import { UserContext } from "./context/UserContext";
import GeneralRouter from "./routers/general";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full">
        <UserManager />
      </div>
    </AppContextProvider>
  );
}

export default App;

function UserManager({ Component, pageProps }) {
  const { user, setUser } = useContext(UserContext);
  const { token, setToken } = useContext(TokenContext);

  return <GeneralRouter />;
}
