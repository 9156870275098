import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import { PrinterServices } from "src/services/PrinterServices";

export function CountPrint() {
    const navigate = useNavigate();
    const { idOrderCustomer, typeService, tableNumber } = useParams();

    const { cart, setCart } = useContext(CartContext);

    const currentDate = new Date();

    // You can extract various components of the local date and time
    const localYear = currentDate.getFullYear(); // Get the current local year
    const localMonth = currentDate.getMonth() + 1; // Get the current local month (1-12)
    const localDay = currentDate.getDate(); // Get the current local day of the month (1-31)
    const localHours = currentDate.getHours(); // Get the current local hour (0-23)
    const localMinutes = currentDate.getMinutes(); // Get the current local minute (0-59)
    const localSeconds = currentDate.getSeconds();
    const printerServices = new PrinterServices();

    useEffect(() => {
        fetch();
    }, []);

    async function fetch() {
        excPrint();
    }

    async function excPrint() {
        const printerNames = [
            "DISMORE_CUSTOMER1",
            "DISMORE_KITCHEN1"
        ];

        const json = buildJsonReceipt(cart, printerNames, idOrderCustomer, typeService);
        printerServices.submitPrintJob({ json, id: idOrderCustomer });

        cart.forEach((singleMenu) => {
            if (!singleMenu.buy) return;
            const jsonSingleMenu = singleMenuJson(singleMenu, idOrderCustomer, typeService);
            printerServices.submitPrintJob({ json: jsonSingleMenu, id: idOrderCustomer });
        });

        await delay(1500);

        navigate(`/rating/${idOrderCustomer}`);
    }

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function printNextValue(props, index, cartLength) {
        const printWindow = window.open("", "", "width=600,height=400");
        printWindow.document.write(
            `
  <html>

<head>
    <title>Document</title>

    <style>
    @import url('https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

    @font-face {
        font-family: print;
        src: url('./assets/fonts/wpc1252.ftx');
    }

        html,
        br,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
        }

        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
            display: block;
        }

        body {
            line-height: 1;
        }

        ol,
        ul {
            list-style: none;
        }

        blockquote,
        q {
            quotes: none;
        }

        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
            content: '';
            content: none;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
        }

        .heading {
            text-align: center;
        }

        .type-service {
            text-align: center;
        }

        .flex {
            display: flex;
        }

        .count {
            margin-right: 10px;
        }

        .justify-between {
            justify-content: space-between;
        }

        .f-s-r {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
        }

        .f-s-m {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
        }

        .f-s-sb {
            font-size: 12px;
            line-height: 16px;
            font-weight: 800;
        }

        .f-p1-r {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }

        .f-p1-m {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }

        .f-p1-sb {
            font-size: 16px;
            line-height: 24px;
            font-weight: 800;
        }

        .f-p2-r {
            font-size: 10px;
            line-height: 20px;
            font-weight: 400;
        }

        .f-p2-m {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
        }

        .f-p2-sb {
            font-size: 14px;
            line-height: 20px;
            font-weight: 800;
        }

        .f-h4 {
            font-size: 16px;
            line-height: 28px;
            font-weight: 800;
        }

        .f-h5 {
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
        }

        hr {
            margin-block-end: 2px;
            margin-block-start: 2px;
        }
        body {
            padding-left: 16px;
            padding-right: 16px;
        }
    </style>
</head>

<body>
    <div class="heading">
        <h1 class="f-h4">New Order</h1>
        <p class="f-p2-r">Queue No: ${idOrderCustomer}</p>
    </div>
    <div class="flex justify-between">
        <h4 class="f-p2-r">Order Ticket</h4>
        <h4 class="f-p2-r">${index + 1} / ${cartLength}</h4>
    </div>
    <div class="flex justify-between">
        <h4 class="f-p2-r">${localYear}-${localMonth}-${localDay}</h4>
        <h4 class="f-p2-r">${localHours}:${localMinutes}:${localSeconds}</h4>
    </div>
    <hr />
    <h4 class="type-service f-h5">${typeService == "dine-in" ? "Dine In" : "Take Away"
            }</h4>
    <hr />
    <div class="flex">
        <div class="count">
            <h4 class="f-p2-m">${props.amount ?? "1"}x</h4>
        </div>
        <div class="product">
            <h4 class="f-p2-m">${props.name ?? "-"}</h4>
            <p class="f-p2-r">${props.notes ?? "-"}</p>
        </div>
    </div>
</body>

</html>
`
        );
        printWindow.document.close();
        printWindow.print();
        await delay(1000);
        printWindow.close();
    }

    return (
        <div>
            {/* <button onClick={printNextValue}>Print Next Value</button> */}
            {/* <button
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Reload
      </button> */}
        </div>
    );
}

function buildJsonReceipt(cart, printing, idOrderCustomer, typeService) {
    const subTotal = cart.reduce(
        (temp, x) => (x.buy == true ? temp + x.price * x.amount : temp),
        0
    );

    const time = new Date().toLocaleString("id-ID", {
        timeZone: "Asia/Jakarta",
    });

    return {
        printing: printing,
        moneyAmount: rupiahFormat(parseInt(subTotal)),
        returnAmount: rupiahFormat(0),
        levelNumber: "-",
        tableNumber: "-",
        idOrderCustomer: idOrderCustomer,
        typeService: typeService,
        typePayment: 'QRIS',
        user: {
            name: "-"
        },
        orderTime: time,
        machine: 'Self Service',
        orders: cart.map((x) => {
            if (x.buy == true)
                return {
                    name: x.name,
                    amount: x.amount,
                    notes: x.notes,
                    originalPrice: rupiahFormat(x.price),
                    totalPrice: rupiahFormat(x.price * x.amount),
                };
        }),
        subTotal: rupiahFormat(subTotal),
        discount: rupiahFormat(0),
        tax: rupiahFormat(0),
        total: rupiahFormat(subTotal),
    };
}

function singleMenuJson(cart, idOrderCustomer, typeService) {
    const time = new Date().toLocaleString("id-ID", {
        timeZone: "Asia/Jakarta",
    });

    return {
        printing: ["DISMORE_SINGLE_MENU1"],
        idOrderCustomer: idOrderCustomer,
        typeService: typeService,
        typePayment: 'QRIS',
        orderTime: time,
        user: {
            name: "-"
        },
        machine: 'Self Service',
        orders: [{
            name: cart.name,
            amount: cart.amount,
            notes: cart.notes,
        }],
    };
}

function rupiahFormat(angka) {
    const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
    });
    return formatter.format(angka);
}
