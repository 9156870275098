import * as Sentry from "@sentry/react";

export const initSentry = () => {
    Sentry.init({
        dsn: "https://33a58ba7836e270855e4e986e3e83d21@o4506456989630464.ingest.sentry.io/4506517317091328",
        integrations: [
            new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/[a-zA-Z0-9.-]+\dismore\.tech/],
            }),
            new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
