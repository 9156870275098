import { useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import iconScan from "src/assets/icon/barcode-scan.png";
import imageLogo from "src/assets/images/logo.png";
import { AdvertisePopUpContext } from "src/context/AdvertisePopUpContext";
import { CartContext } from "src/context/CartContext";
import { GET_SUBMENUS } from "src/graphql/query/Submenu";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { ErrorServices } from "src/services/ErrorServices";
import { AdvertiseDefaultComponent } from "src/ui/components/advertise";
import { ButtonComponentLite } from "src/ui/components/button/button-component";
import { ErrorComponent } from "src/ui/components/error/error-component";
import { LoadingComponent } from "src/ui/components/loading/loading-component";
import { MenuComponent } from "./component/MenuComponent";
import { ScanMenuComponent } from "./component/ScanMenuComponent";
import { SubMenuComponent } from "./component/SubMenuComponent";

export function MenuPage() {
  const { typeService } = useParams();
  const navigate = useNavigate();

  const errorServices = new ErrorServices();

  const { cart, setCart } = useContext(CartContext);

  const { showAdvertisePopUp, setShowAdvertisePopUp } = useContext(
    AdvertisePopUpContext
  );

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
    refetch: refetchTenants,
  } = useQuery(GET_TENANTS);

  const [menuActive, setMenuActive] = useState("scan-menu");

  const {
    loading: loadingSubmenus,
    error: errorSubmenus,
    data: dataSubmenus,
    refetch: refetchSubmenus,
  } = useQuery(GET_SUBMENUS, { variables: { menuId: menuActive } });

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) {
    errorServices.Create({
      message: JSON.stringify(errorTenants),
    });
    return <ErrorComponent />;
  }

  return (
    <div className="h-full relative overflow-hidden flex flex-col items-center">
      {showAdvertisePopUp && typeService == "dine-in" ? (
        <AdvertiseDefaultComponent
          handleClose={() => {
            setShowAdvertisePopUp(false);
          }}
        />
      ) : null}

      {/* <IklanComponent /> */}

      <div className="w-11/12 flex py-[20px]">
        <div className="w-1/2 flex items-center gap-10">
          <div>
            <img src={imageLogo} className="w-[160px]" />
          </div>
          <div className="">
            <h1 className="font-extrabold text-[35px]">Cafetaria Unair B</h1>
          </div>
        </div>
        <div className="w-1/2 flex items-center justify-end gap-10">
          <div className="">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FiArrowLeftCircle size={56} />
            </button>
          </div>
        </div>
      </div>

      <div className="w-full bg-white overflow-scroll grow grid grid-cols-12 gap-5 px-[50px] mb-[30px]">
        <div className="overflow-scroll h-full col-span-2">
          <MenuComponent
            key={"scan-menu"}
            id={"scan-menu"}
            active={menuActive == "scan-menu"}
            image={iconScan}
            name={"Scan Menu"}
            handleClick={(id) => {
              setMenuActive(id);
            }}
          />

          {dataTenants.tenants.map((tenant, index) => {
            return tenant.menus.map((menu, i) => {
              if (menu.isPromotion == true)
                return (
                  <MenuComponent
                    key={menu.id}
                    id={menu.id}
                    active={menuActive == menu.id}
                    image={menu.displayPic}
                    name={menu.name}
                    handleClick={(id) => {
                      setMenuActive(id);
                    }}
                  />
                );
            });
          })}
        </div>
        <div className="col-span-10 overflow-scroll h-full flex flex-col">
          <div className="bg-slate-100 rounded-3xl grow py-[30px] px-[40px]">
            <h2 className="font-extrabold text-[45px]">
              {dataTenants.tenants.map((tenant, index) =>
                tenant.menus.map((menu, i) => {
                  if (menu.id == menuActive) {
                    return menu.name;
                  }
                })
              )}
            </h2>

            {menuActive == "scan-menu" ? (
              <ScanMenuComponent />
            ) : (
              <div className="mt-5 flex flex-col gap-10 h-full">
                {dataSubmenus &&
                  dataSubmenus.submenus.map((submenu, index) => {
                    return (
                      <SubMenuComponent
                        key={index}
                        id={submenu.id}
                        name={submenu.name}
                        menuId={menuActive}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full bg-slate-100 flex items-center justify-between px-[75px] py-[35px]">
        <div className="">
          <h2 className="font-extrabold text-[35px]">My Order</h2>
          <p className=" text-[20px]">
            {cart.length == 0
              ? "Your cart is empty"
              : `
              Total IDR ${cart.reduce(
                (temp, x) => temp + x.price * x.amount,
                0,
                0
              )} 
                | Item: ${cart.reduce((temp, x) => temp + x.amount, 0)}`}
          </p>
        </div>
        <div>
          <ButtonComponentLite
            title="View Order"
            handle={() => {
              navigate(`/${typeService}/order-list`);
            }}
          />
        </div>
      </div>

      {/* <MarqueComponent /> */}
    </div>
  );
}
