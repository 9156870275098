import image404 from "src/assets/images/404.png";

export function Page404() {
  return (
    <div className="w-full h-screen flex items-center">
      <div className="bg-white w-full h-fit flex flex-col items-center text-center">
        <div className="w-9/12">
          <div className="w-full mx-auto flex justify-center">
            <img src={image404} alt="" />
          </div>
          <h2 className="font-semibold text-[64px] mt-20">
            404 Page Not Found
          </h2>
          <p className="text-[30px] mt-4 italic">
            Please register yourself through our casier
          </p>
        </div>
      </div>
    </div>
  );
}
