import axios from "axios";
import { apiUrl } from "../configs/Url";
import { headers } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class PrinterServices {
    async submitPrintJob({ json, id }) {
        try {
            const res = await axios.post(
                apiUrl + "/self_service/printers/queues",
                {
                    payload: json,
                    order_customer_transaction_id: id,
                },
                {
                    headers: {
                        ...headers,
                        "Content-Type": "application/json",
                        Accept: "*/*",
                    },
                }
            );
            if (res.status === 200) {
                return res.data;
            } else {
                handleOtherStatusCodes(res.status);
                return;
            }
        } catch (err) {
            handleAxiosError(err);
            return;
        }
    }
}
