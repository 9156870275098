import { FiX } from "react-icons/fi";
// import imagePopUp from "src/assets/images/advertise/pop-up.jpeg";

export const AdvertiseDefaultComponent = ({ handleClose }) => {
  return (
    <dialog className="z-40 w-full h-full flex items-center justify-center bg-black bg-opacity-80">
      <div className="relative rounded-3xl overflow-hidden">
        <button
          className="text-white absolute right-5 top-5"
          onClick={() => {
            handleClose();
          }}
        >
          <FiX size={48} />
        </button>
        {/* <img src={imagePopUp} /> */}
      </div>
    </dialog>
  );
};
