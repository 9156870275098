import { useNavigate, useParams } from "react-router-dom";
import typeServiceImage from "src/assets/images/enjoy.png";
import { MarqueComponent } from "src/ui/components/marque";
import imageMan from "src/assets/images/man.png";
import imageWoman from "src/assets/images/woman.png";
import iconStar from "src/assets/icon/star.png";
import iconStarFill from "src/assets/icon/star-fill.png";
import { useEffect, useState } from "react";
import { NavbarComponent } from "src/ui/components/navbar/navbar-component";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useMutation } from "@apollo/client";
import { CREATE_RATING } from "src/graphql/mutation/CreateRating";

export function RatingPage() {
  const { idOrderCustomer } = useParams();

  const [
    createRating,
    {
      loading: loadingCreateRating,
      error: errorCreateRating,
      data: dataCreateRating,
    },
  ] = useMutation(CREATE_RATING);

  const [star, setStar] = useState(0);
  const [buttonClick, setButtonClick] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  }, []);

  const addRating = async (value) => {
    const res = await createRating({
      variables: {
        input: {
          orderCustomerId: idOrderCustomer,
          value: value,
        },
      },
    });

    if (loadingCreateRating == false && errorCreateRating == undefined) {
      window.location.href = "/";
    }
  };

  return (
    <div className="h-full relative overflow-hidden">
      <div className="fixed h-full w-full z-0 overflow-hidden">
        <img src={typeServiceImage} alt="" className="w-full h-full" />
      </div>
      <div className="fixed h-full w-full bg-gray-300 bg-opacity-70 z-0"></div>

      <div className="absolute z-10 w-full">
        <NavbarComponent
          title="Rating"
          type="dark"
          rightIcon={FiHome}
          handleRight={() => {
            window.location.href = "/";
          }}
        />
      </div>
      <div className="relative h-full w-full flex flex-col items-center justify-center">
        <div className="w-10/12 flex flex-col justify-center relative">
          <div className="flex flex-col justify-center items-center absolute w-full -top-96">
            <h2 className="text-[80px] text-center text-black font-bold mb-8">
              Thank YOU <br /> for your Purchase
            </h2>
            <h2 className="text-[40px] text-center text-gray-700 font-bold">
              Please Rate Us
            </h2>
          </div>
          <div className="flex mt-10 justify-center">
            <div className="flex w-3/4 justify-center gap-5">
              {Array.from(Array(5).keys()).map((item, index) => {
                return (
                  <button
                    onClick={async () => {
                      if (!buttonClick) {
                        setButtonClick(true);
                        setStar(index + 1);

                        const res = await addRating(index + 1);
                      }
                    }}
                  >
                    <img
                      src={iconStar}
                      className={`w-48 ${star <= index ? "block" : "hidden"}`}
                    />
                    <img
                      src={iconStarFill}
                      className={`w-48 ${
                        star >= index + 1 ? "block" : "hidden"
                      }`}
                    />
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 flex gap-0">
          <img src={imageMan} className="h-[750px]" />
          <img src={imageWoman} className="h-[750px]" />
        </div>
      </div>

      {/* <div className="absolute bottom-0">
        <MarqueComponent />
      </div> */}
    </div>
  );
}
