import React, { useContext, useEffect, useState } from "react";
import { FiMinus, FiPlus, FiTrash } from "react-icons/fi";

export function ProductComponent({
  product,
  handleMin,
  handlePlus,
  handleBuy,
  removeProduct,
}) {
  const [count, setCount] = useState(1);
  const [buy, setBuy] = useState(false);

  useEffect(() => {
    setCount(product.amount);
    setBuy(product.buy);
  }, [product.buy]);

  return (
    <div className="flex px-[75px] py-2 items-center justify-between gap-5">
      <div className="flex items-center gap-10">
        <div className="flex items-center">
          <input
            id="default-checkbox"
            type="checkbox"
            onChange={() => {
              handleBuy();
              setBuy(!buy);
            }}
            checked={buy}
            className="w-6 h-6 text-main bg-gray-100 border-gray-300 rounded focus:ring-main dark:focus:ring-main dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
        <div
          className={`h-[150px] w-[150px] py-2 px-2 rounded-xl flex flex-col items-center overflow-hidden bg-slate-100`}
        >
          <div className="w-[130px] h-[100px] overflow-hidden flex justify-center items-center">
            <img src={product.displayPic} className="w-full" />
          </div>
          <h4 className="font-bold text-[14px] mt-2 w-[120px] line-clamp-2 text-center leading-none">
            {product.name}
          </h4>
        </div>

        <div>
          <h3 className="font-medium text-[24px] line-clamp-2">
            {product.name}
          </h3>
          <div className="bg-gray-200 rounded-lg py-2 px-3 mt-2 w-fit">
            <h4 className="text-main text-lg font-bold">
              Notes : <span className="font-medium">{product.notes}</span>
            </h4>
          </div>
          <h2 className="font-bold text-[32px] text-main mt-1">
            Rp{product.price}
          </h2>
          <div className="flex mt-3 gap-5">
            <div className="text-center w-[30px]">
              <h1 className="font-bold text-[20px]">{count} x</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <button
          onClick={removeProduct}
          className="bg-main text-white rounded-lg px-4 py-4"
        >
          <FiTrash size={24} />
        </button>
      </div>
    </div>
  );
}
