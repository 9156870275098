export function ProductComponent(props) {
  return (
    <button
      onClick={() => {
        props.handleClick(props.id);
      }}
      className={`w-[220px] h-[310px] rounded-xl flex flex-col items-center overflow-hidden group hover:bg-secondary ${
        props.active ? "bg-main" : "bg-white"
      }`}
    >
      <div className="w-[220px] h-[220px] overflow-hidden flex justify-center items-center">
        <img src={props.image} className="w-full" />
      </div>
      <div className="grow w-full text-center px-4 py-0 flex flex-col justify-center items-center">
        <h4 className="font-bold text-[14px] line-clamp-3 h-fit leading-tight">
          {props.name}
        </h4>
        <h4 className="font-bold text-[14px] line-clamp-3 h-fit leading-tight mt-1 text-main">
          Rp {props.price}
        </h4>
      </div>
    </button>
  );
}
